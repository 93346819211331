import './App.css';
import Fronter from './Fronter';

interface AppProps {
  isProduction: boolean;
}

export default function App(props:AppProps) {
  return (
    <div className={`App ${props.isProduction ? 'IsProd' : 'IsDev'}`}>
      <header className="App-header">
        <Fronter />
      </header>
    </div>
  );
}